<template>
	<div class="wrapper">
		<side-bar :sidebar-links="sidebarLinks" type="sidebar" />

		<div class="main-panel">
			<top-navbar />

			<dashboard-content @click.native="toggleSidebar" />
		</div>
	</div>
</template>

<script>
import { SideBar } from '@/components/UIComponents';
import { displaySidebar } from '@/store/mutation-types';

import TopNavbar from './Dashboard/TheTopNavbar.vue';
import DashboardContent from './Dashboard/TheContent.vue';

export default {
	name: 'DashboardLayout',
	components: {
		TopNavbar,
		DashboardContent,
		SideBar,
	},
	computed: {
		sidebarLinks() {
			if (this.$store.state && this.$store.state.currentAppName === 'eshop') {
				const hasDirectSale = this.$store.state[this.$store.state.currentAppName]?.currentUser?.DirectSale === 'True';
				if (!hasDirectSale) {
					return this.$getConst('sidebarLinksNoDirectSale');
				}
			}
			return this.$getConst('sidebarLinks');
		},
	},
	methods: {
		toggleSidebar() {
			if (this.$store.state.showSidebar) {
				this.$store.commit(displaySidebar, false);
			}
		},
	},
};
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
	.navbar > .container-fluid {
		justify-content: end;
	}
}
</style>
