<template>
	<navbar v-model="showNavbar">
		<div class="navbar-wrapper">
			<div class="navbar-minimize">
				<el-tooltip :content="sidebarTooltip" placement="bottom-start">
					<button
						id="minimizeSidebar"
						class="btn btn-icon btn-round"
						type="button"
						@click="minimizeSidebar">
						<i class="fas fa-bars text-center visible-on-sidebar-mini" />
						<i class="fas fa-ellipsis-v text-center visible-on-sidebar-regular" />
					</button>
				</el-tooltip>
			</div>
			<div class="navbar-toggle">
				<navbar-toggle-button @click.native="toggleSidebar" />
			</div>
			<a
				v-if="currentAppName === appNameList.pickup"
				class="btn-warning btn btn-icon btn-round mr-2 mr-sm-3 ml-3 ml-sm-0 genesis-button"
				href="https://prodejny.genesis-eshop.cz"
				target="_blank"
				title="Genesis e-shop">
				<i class="fa fa-coins" />
			</a>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item v-for="(breadcrumb, index) in $route.meta.breadcrumb" :key="index" :to="{ name: breadcrumb.link }">
					{{ breadcrumb.name }}
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	</navbar>
</template>

<script>
import { Breadcrumb as ElBreadcrumb, BreadcrumbItem as ElBreadcrumbItem, Tooltip as ElTooltip } from 'element-ui';

import { Navbar, NavbarToggleButton } from '@/components/UIComponents';
import { appNameList } from '@/globalConstants';
import { displaySidebar, toggleSidebar } from '@/store/mutation-types';

export default {
	name: 'TheTopNavbar',
	components: {
		Navbar,
		NavbarToggleButton,
		ElBreadcrumb,
		ElBreadcrumbItem,
		ElTooltip,
	},
	data() {
		return {
			showNavbar: false,
			appNameList,
		};
	},

	computed: {
		sidebarTooltip() {
			return this.$store.state.sidebar.isMinimized ? this.$t('admin.tooltips.showSidebar') : this.$t('admin.tooltips.hideSidebar');
		},
		currentAppName() {
			return this.$getConst('appName');
		},
	},
	methods: {
		toggleSidebar() {
			this.$store.commit(displaySidebar, !this.$store.state.sidebar.showSidebar);

			if (this.$store.state.sidebar.showSidebar) {
				// HACK: browser don't auto-scroll to right after the sidebar display
				setTimeout(() => {
					document.querySelector('.sidebar').scrollIntoView({ behavior: 'smooth' });
				}, 100);
			}
		},
		minimizeSidebar() {
			this.$store.commit(toggleSidebar);
		},
		toggleNavbar() {
			this.showNavbar = !this.showNavbar;
		},
	},
};
</script>

<style lang="scss" scoped>
.el-breadcrumb__item:first-child {
	font-size: 1.5em;
}

.el-breadcrumb__item:not(:first-child) {
	margin: 0.25em 0 0 0;
	font-size: 1em;
}

.genesis-button {
	line-height: 2.375rem;
}

@media screen and (max-width: 768px) {
	.navbar-wrapper {
		flex-direction: row-reverse;
		margin-right: -10px;
	}

	::v-deep .navbar > .container-fluid {
		justify-content: end;
	}

	.genesis-button {
		margin-left: 1rem;
		margin-right: 0;
	}

	.navbar .navbar-wrapper .navbar-toggler {
		padding-left: 0.25rem;
		transform: rotate(180deg);
	}
}
</style>
