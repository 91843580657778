<template>
	<div class="content">
		<transition name="fade" mode="out-in">
			<router-view />
		</transition>
	</div>
</template>

<script>
export default {
	name: 'TheContent',
};
</script>
